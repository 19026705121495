<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')"  id="user-history" no-body>

    <div class="page-header">
      <b-row>
        <b-col md="12">
          <div class="filters">

            <div class="filter-column">
              <v-select
                  @input="getHistory"
                  placeholder="Կազմակերպություն"
                  id="vue-select-region"
                  :reduce="prefix => prefix.id"
                  v-model="filters.company"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="users"
                  class="selectorFilterApi"
              />
            </div>
            <div class="filter-column">
              <b-form-input
                  @change="filterName"
                  v-model="filters.name"
                  autocomplete="off"
                  @keydown="disableSymbol($event)"
                  id="owner_name"
                  type="text"
                  placeholder="Փնտրել..."
                  class="w-full text-uppercase opinionFilter"
                  clearable
              />
            </div>

          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col md="12">
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :items="history"
            :fields="fields"
        >
          <template #cell(createdAt)="data">
            {{data.value | formatDate}}
          </template>

        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
              :label="'Գտնվել է ' + pagination.total + ' տող'"
              label-cols="3"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
          >
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
                @input="changePage"
                v-model="pagination.current_page"
                :total-rows="pagination.total"
                :per-page="pagination.count"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-col>
    </b-row>

  </b-card-code>
</template>

<script>

import moduleApi from '@/store/api/moduleApi'
import store from '@/store'
import { BTable, BCardBody, BFormGroup, BPagination, BRow, BCol, BFormInput } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode'
import vSelect from 'vue-select'
import companyModule from '@/store/companies/companyModule'

export default {
  name: 'api-history',
  components: {
    BCardCode,
    BCardBody,
    BTable,
    BFormGroup,
    BPagination,
    BRow,
    BCol,
    BFormInput,
    vSelect
  },
  data() {
    return {
      API_STORE_MODULE_NAME: 'apiModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      filters: {
        company: null
      },
      fields: [
        {
          key: 'createdAt',
          label: 'Ամսաթիվ'
        },
        {
          key: 'user.company.name',
          label: 'Կազմակերպություն'
        },
        {
          key: 'user.name',
          label: 'Օգտատեր'
        },
        {
          key: 'ip',
          label: 'IP հասցե'
        },
        {
          key: 'search',
          label: 'Որոնում'
        }
      ],
    }
  },
  computed: {
    history() {
      return this.$store.state[this.API_STORE_MODULE_NAME].history
    },
    pagination() {
      return this.$store.state[this.API_STORE_MODULE_NAME].pagination
    },
    users() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
        label: e.name,
        id: e._id
      }))
    }
  },
  created() {
    if (!store.hasModule(this.API_STORE_MODULE_NAME)) store.registerModule(this.API_STORE_MODULE_NAME, moduleApi)
    this.getHistory()
    this.getUsers()
  },
  methods: {

    getHistory() {
      const filterData = {
        ...this.filters,
        current_page: this.pagination.current_page
      }
      this.$vs.loading()
      this.$store.dispatch(`${this.API_STORE_MODULE_NAME}/getSearchHistory`, this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
            const element = document.getElementById('group-menu-badge-api')
            if (element) {
              element.innerText = 0
            }
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })

    },

    getUsers() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    changePage(page) {
      this.pagination.current_page = page
      this.getHistory()
    },
    filterName() {
      this.pagination.current_page = 1;
      this.getHistory()
    },
    disableSymbol(e) {
      if (!(/^[*a-zA-Z0-9ղցվա]*$/.test(e.key))) {
        e.preventDefault()
      }
    },
  }
}
</script>
<style lang="scss">
#user-history {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 15rem);
    min-height: calc(var(--vh, 1vh) * 100 - 15rem);
  }
}
.selectorFilterApi {
  min-width: 300px
}
</style>
